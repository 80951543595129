import encrypt from "../../../redux/actions/shared/sharedActions";
import UNIVERSAL, { chatRefreshTimer, fetchRetryDelay } from "../../../config";
import { set_loader, unset_loader } from "../loader/loader_action";
import {
  SET_ACKNOWLEDGED,
  SET_ORGANIZATION,
  SET_NEW_ORGANIZATION,
  SET_NEW_ORGANIZATION_LOGO,
  SET_CHAT_QUESTION,
  SET_CHAT_ANSWER,
  SET_CHATS_CONVERSATION,
  SET_CHAT_GROUP,
  SET_SELECTED_CHAT,
  SET_SEARCHED_ORGANIZATION,
  SET_SEARCHED_TEXT,
  SET_DISCLAIMER,
  SET_HELP_CENTER_DRAWER,
  SET_FOLLOWUP_QUES,
  SET_MAIN_DRAWER,
  SET_ADD_CHAT,
} from "../../../constants/esgGptConstant";
import { set_snack_bar } from "../snackbar/snackbar_action";
import { socket } from "../../../socket";
import { setChatTokens } from "../login/loginAction";

// chat timers
import { startChatTimer } from "../chatTimers/ChatTimersActions";

export const setSearchedOrg = (payload) => {
  return {
    type: SET_SEARCHED_ORGANIZATION,
    payload,
  };
};
export const setDisclaimer = (payload) => {
  return {
    type: SET_DISCLAIMER,
    payload,
  };
};
export const setSearcheText = (payload) => {
  return {
    type: SET_SEARCHED_TEXT,
    payload,
  };
};
export const setAcknowledged = (payload) => {
  return {
    type: SET_ACKNOWLEDGED,
    payload,
  };
};

export const setOrganization = (payload) => {
  return {
    type: SET_ORGANIZATION,
    payload,
  };
};

export const setNewCompanyDetails = (payload) => {
  return {
    type: SET_NEW_ORGANIZATION,
    payload,
  };
};

export const setNewCompanyLogo = (payload) => {
  return {
    type: SET_NEW_ORGANIZATION_LOGO,
    payload,
  };
};

export const setChatQuestion = (payload) => {
  return {
    type: SET_CHAT_QUESTION,
    payload,
  };
};

export const setChatAnswer = (payload) => {
  return {
    type: SET_CHAT_ANSWER,
    payload,
  };
};

export const setChatConversations = (payload) => {
  return {
    type: SET_CHATS_CONVERSATION,
    payload,
  };
};

export const setChatGroups = (payload) => {
  return {
    type: SET_CHAT_GROUP,
    payload,
  };
};

export const setSelectedChat = (payload) => {
  return {
    type: SET_SELECTED_CHAT,
    payload,
  };
};

export const setHelpCenterDrawer = (payload) => {
  return {
    type: SET_HELP_CENTER_DRAWER,
    payload,
  };
};

export const setMainDrawer = (payload) => {
  return {
    type: SET_MAIN_DRAWER,
    payload,
  };
};

export const setFollowupQues = (payload) => {
  return {
    type: SET_FOLLOWUP_QUES,
    payload,
  };
};

export const setFilter = (payload) => {
  return {
    type: "SET_FILTER",
    payload,
  };
};

export const setAddChat = (payload) => {
  return {
    type: SET_ADD_CHAT,
    payload,
  };
};

export const createOrganizationEsgGpt = (
  token,
  name,
  isin,
  sector,
  industry,
  industry_code,
  logo,
  country,
  revenue
) => {
  return (dispatch) => {
    dispatch(set_loader);
    var formData = new FormData();
    const data = encrypt({
      "user-token": token,
      name,
      isin,
      sector,
      industry,
      industry_code,
      country,
      revenue,
      // logo,
    });

    formData.append("data", data);
    formData.append("logo", logo);

    return fetch(
      UNIVERSAL.BASEURL + "/organization/create_organization_esg_gpt",
      {
        method: "POST",

        body: formData,
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setAcknowledged(responseJson?.status));
          dispatch(setNewCompanyDetails(responseJson?.result));
          dispatch(viewOrganizationEsgGpt(token));
          dispatch(set_snack_bar(true, responseJson?.message));
        } else {
          dispatch(set_snack_bar(true, responseJson?.message));
        }

        dispatch(unset_loader);
      });
  };
};
export const deleteOrganizationEsgGpt = (token, id) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      id,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/organization/delete_organization_esg_gpt",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          console.log(responseJson);
          // dispatch(set_snack_bar(responseJson.status.responseJson.message));
          // dispatch(setNewCompanyDetails(responseJson?.result));
          dispatch(viewOrganizationEsgGpt(token));
        }

        dispatch(unset_loader);
      });
  };
};

export const viewOrganizationEsgGpt = (
  token,
  limit,
  starting_after,
  search_keyword,
  view = 1,
  sort_value = 1,
  geography = ["IN", "GB", "US", "NL", "AE"],
  hasFiltered = false
) => {
  return (dispatch) => {
    dispatch(set_loader);
    hasFiltered && dispatch(setOrganization({}));
    const data = encrypt({
      "user-token": token,
      limit: limit || 10,
      starting_after: starting_after || 0,
      keyword: search_keyword || "",
      view,
      sort_value,
      geography,
    });

    return fetch(
      UNIVERSAL.BASEURL + "/organization/view_organization_esg_gpt",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: data }),
      }
    )
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setOrganization(responseJson?.result));
          // responseJson?.result?.favorites.length > 0
          //   ? dispatch(setNewCompanyDetails(responseJson?.result?.favorites[0]))
          //   : dispatch(
          //       setNewCompanyDetails(responseJson?.result?.repositories[0])
          //     );
        }

        dispatch(unset_loader);
      });
  };
};

// not a dispatch action
const getChatHistory = async (
  token,
  is_company_based,
  chat_group_id,
  company_id
) => {
  const historyData = encrypt({
    "user-token": token,
    is_company_based,
    chat_group_id: chat_group_id || "",
    company_id: company_id || "",
  });

  let chatHistory = [];

  await fetch(UNIVERSAL.BASEURL + "/python_apis/get_chat_history_for_python", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ data: historyData }),
  })
    .then((response) => response.json())
    .then((responseJson) => {
      // console.log("api response", responseJson);
      chatHistory = responseJson?.result;
    });

  return chatHistory;
};

function generateRandomId(length) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return result;
}

// create new chat group
export const createChatGroup = (token) => {
  return (dispatch) => {
    dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/create_chat_group", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status) {
          return responseJson?.result?._id;
        }

        dispatch(unset_loader);
      });
  };
};

// function to create question obj
export const createNewQues = (
  token,
  question,
  company_id,
  chat_group_id,
  is_company_based,
  isin
) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      question,
      company_id,
      chat_group_id,
      is_company_based,
      isin: isin || "",
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/add_chat", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response?.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status) {
          return responseJson?.result;
        }
      });
  };
};

// Helper function to wait for a specified number of milliseconds
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Function to attempt fetching answer with retry logic
const fetchAnswerWithRetry = async (
  data,
  attempts = 3,
  delayMs = fetchRetryDelay
) => {
  try {
    const response = await fetch(
      UNIVERSAL.BASEURL + "/python_apis/getQuesAnswer",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data }),
      }
    );

    const responseJson = await response.json();

    if (responseJson.status && responseJson?.result?.answer) {
      return responseJson.result;
    } else {
      throw new Error("Answer not found");
    }
  } catch (error) {
    if (attempts >= 1) {
      console.log(`Attempt failed, retrying in ${delayMs / 1000} seconds...`);
      await delay(delayMs);
      return fetchAnswerWithRetry(data, attempts - 1, delayMs); // Retry
    } else {
      throw new Error("Failed to fetch answer after multiple attempts");
    }
  }
};

// function to fetch answer from db with question_id
export const fetchAnswerFromMongo = (question_id, is_company_based) => {
  return async (dispatch, getState) => {
    const token = getState().login.token;

    const data = encrypt({
      "user-token": token,
      question_id,
      is_company_based,
    });

    try {
      // Fetch answer with retry
      const result = await fetchAnswerWithRetry(data);

      dispatch(
        setAddChat({
          _id: question_id,
          isLoading: false,
          thinking: "",
          answer: result.answer, // Access the answer object directly
        })
      );
    } catch (error) {
      dispatch(
        setAddChat({
          _id: question_id,
          isLoading: false,
          thinking: "",
          answer: "",
        })
      );
      console.error("Error fetching answer:", error);
    }
  };
};

// socket >> thinking >> hello, chat_id, room_id >> dispatch() >> convo_id === room_id
// {question: answer:, thinking}
// useEffects

// ------------- Socket Actions Handles -------------
// this action will be used for creating question
export const oldGetGptAnswer = (
  token,
  company_id,
  question,
  chat_id,
  is_company_based,
  isin,
  companyName
) => {
  return async (dispatch, getState) => {
    const { chats } = getState().esgGptReducer;
    const { chat_tokens } = getState().login;

    const random_id = generateRandomId(8);

    const chatObj = {
      _id: random_id,
      question,
      isLoading: true,
      thinking: "",
      answer: "",
    };

    const is_new_chat = !is_company_based && !chat_id;

    if (!is_company_based && !chat_id) {
      const newConvoId = await dispatch(createChatGroup(token));

      chat_id = newConvoId;
    }

    // selecting socket to use based on if comapny name is present
    const socketToUse = socket;

    const currentRoomId =
      companyName && is_company_based ? company_id : chat_id;

    await dispatch(setChatConversations([...chats, chatObj]));

    const chatHistory = await getChatHistory(
      token,
      is_company_based,
      chat_id,
      company_id
    );

    function task1() {
      question = {
        question_id: chatObj?._id,
        question: question,
        isin: isin || undefined,
        chatHistory: chatHistory,
        user_id: localStorage.getItem("user_id"),
        company_id: company_id || undefined,
        chat_id: chat_id || undefined,
      };

      console.log("Question is: ", question);

      // make use of socket.emit for direct chat and socketCompany.emit for company based chat

      new Promise(function (resolve, reject) {
        resolve(socketToUse.emit("start_chat_session", question));
      });
    }

    let finalResultReceived = false; // Flag to track if final result has been received

    function task2(currentChat) {
      return new Promise(function (resolve, reject) {
        const thinkingListener = async (responseJson) => {
          console.log("Thinking now: ", responseJson);
          if (!finalResultReceived) {
            // Check if final result has not been received
            dispatch(
              setAddChat({
                ...currentChat,
                isLoading: true,
                thinking: responseJson?.data || responseJson,
                answer: "",
              })
            );
          }
        };

        socketToUse.on("thinking", thinkingListener);

        const finalResultListener = async (responseJson) => {
          if (!finalResultReceived) {
            // Check if final result has not been received
            finalResultReceived = true; // Set flag to true

            console.log("Response from socket server: ", responseJson);

            if (responseJson === undefined) {
              responseJson =
                "Unable to generate a response for your message. Please try again.";
            }

            dispatch(
              setAddChat({
                ...currentChat,
                isLoading: false,
                thinking: "",
                answer: responseJson,
              })
            );

            // reduce chat token
            const newChatTokens = chat_tokens <= 0 ? 0 : chat_tokens - 1;
            dispatch(setChatTokens(newChatTokens));
            localStorage.setItem("chat_tokens", newChatTokens);

            resolve(responseJson);
          }
          socketToUse.off("final_result", finalResultListener); // Detach final result listener
          socketToUse.off("thinking", thinkingListener); // Detach thinking listener
        };

        socketToUse.on("final_result", finalResultListener);
      });
    }

    function task3(socketResponse) {
      // const { socketResponse } = getState().esgGptReducer;
      // console.log("response: ", socketResponse);
      const chatData = encrypt({
        "user-token": token,
        question,
        answer: socketResponse,
        company_id,
        chat_id,
        is_company_based,
        isin: isin || "",
      });

      fetch(UNIVERSAL.BASEURL + "/python_apis/add_chat", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ data: chatData }),
      })
        .then((response) => response.json())
        .then((responseJson) => {
          // console.log("api response", responseJson);
          if (responseJson.status) {
            if (!is_company_based && !chat_id) {
              dispatch(viewChatGroups(token));
            }
          }
        });
    }

    function updateChat() {
      // refresh chat groups
      if (!is_company_based && is_new_chat) {
        setTimeout(() => {
          dispatch(viewChatGroups(token));
        }, 500);
      }
    }

    Promise.all([task1(), task2({ ...chatObj })]).then(function (values) {
      updateChat();
    });
    // .then(function (values) {
    //   task3(values[1]);
    // });
  };
};

// action for first step, create question + emit start_chat_session
export const getGptAnswer = (
  token,
  company_id, // company id
  question,
  chat_group_id, // conversation id
  is_company_based,
  isin,
  companyName
) => {
  return async (dispatch, getState) => {
    const { chat_tokens, direct_login } = getState().login;
    const { chats } = getState().esgGptReducer;

    if (!chat_tokens || chat_tokens <= 0) {
      dispatch(
        set_snack_bar(true, "Please purchase Plus Plan to get more tokens")
      );
      return;
    } else {
      // getting new question_id from from backend
      const newQuesObj = await dispatch(
        createNewQues(
          token,
          question,
          company_id,
          chat_group_id,
          is_company_based,
          isin
        )
      );

      if (!newQuesObj?.chat_id) {
        dispatch(set_snack_bar(true, "Error while adding chat"));
        return;
      }

      const question_id = newQuesObj?.chat_id;

      // check if its a new chat
      const isNewChat = !is_company_based && !chat_group_id;

      if (isNewChat) {
        chat_group_id = newQuesObj?.chat_group_id;

        // refresh chat group list
        await dispatch(viewChatGroups(token));
      }

      // create a new chat object to be added to current list of chats for this convo
      const chatObj = {
        _id: question_id,
        question,
        isLoading: true,
        thinking: "",
        answer: "",
      };

      const setConvoTimeout = isNewChat ? 500 : 0;

      // adding created chatObj to list of chats for current convo
      setTimeout(async () => {
        await dispatch(setChatConversations([...chats, chatObj]));
      }, setConvoTimeout);

      // // Start a timer that fetches the answer from MongoDB after 1 minute if no response
      dispatch(
        startChatTimer(question_id, chatRefreshTimer, () =>
          fetchAnswerFromMongo(question_id, is_company_based)
        )
      );

      // --------- Socket work starts here ---------

      // getting chat history to be sent to socket server
      const chatHistory = await getChatHistory(
        token,
        is_company_based,
        chat_group_id,
        company_id
      );

      // selecting socket to use based on if comapny name is present
      // const socketToUse = socket;

      // creating a socketQuesObj to be sent to socket
      const socketQuesObj = {
        user_id: localStorage.getItem("user_id"),
        email: localStorage.getItem("userEmail"),
        question_id: question_id,
        chat_group_id: chat_group_id || company_id,
        message: question,
        chatHistory: chatHistory,
        companyName: companyName,
        isin: isin || undefined,
        is_company_based: is_company_based || false,
        isFrom: "gptwise",
      };

      console.log("Question is: ", socketQuesObj);

      // emitting start_chat_session for socket
      socket.emit("start_chat_session", socketQuesObj);

      // reduce chat token
      const newChatTokens = chat_tokens <= 0 ? 0 : chat_tokens - 1;
      dispatch(setChatTokens(newChatTokens));
      localStorage.setItem("chat_tokens", newChatTokens);

      // ---------- Socket work ends here ----------
    }
  };
};

// this action is for updating the correct thinking state
export const updateThinkingForConvo = (question_id, thinkingMsg) => {
  return (dispatch, getState) => {
    const currentAnswer =
      getState().esgGptReducer.chats.find((chat) => chat?._id === question_id)
        ?.answer || {};

    // console.log("Thinking current ans: ", currentAnswer);

    // this will update the chat after finding the correct questionObj to update
    dispatch(
      setAddChat({
        _id: question_id,
        isLoading: true,
        thinking: thinkingMsg,
        answer: currentAnswer,
      })
    );
  };
};

// this action is for updating the correct answer for question state
export const updateAnswerForConvo = (question_id, answerObj) => {
  return (dispatch, getState) => {
    // Retrieve the current answer for the given question_id from the state
    const currentAnswer =
      getState().esgGptReducer.chats.find((chat) => chat?._id === question_id)
        ?.answer || {};

    // console.log("Answer current ans: ", currentAnswer);

    // If answer exists, merge it with the new answer; otherwise, use the new answer
    const updatedAnswer = currentAnswer
      ? { ...currentAnswer, ...answerObj }
      : answerObj;

    // Dispatch the updated answer
    dispatch(
      setAddChat({
        _id: question_id,
        isLoading: false,
        thinking: "",
        answer: updatedAnswer,
      })
    );
  };
};

// this action is for updating the correct chart for question state
export const updateChartForConvo = (question_id, chartProps) => {
  return (dispatch, getState) => {
    const currentAnswer =
      getState().esgGptReducer.chats.find((chat) => chat?._id === question_id)
        ?.answer || {};

    if (!currentAnswer.data || currentAnswer.data === "") {
      currentAnswer.data = "Processing: Working on final answer...";
    }

    // Merge current answer with the new chart_props
    const updatedAnswer = {
      ...currentAnswer,
      chart_props: chartProps,
    };

    // Dispatching the updated answer with chart_props
    dispatch(
      setAddChat({
        _id: question_id,
        isLoading: false,
        thinking: "",
        answer: updatedAnswer,
      })
    );
  };
};

// ----------------------------------------------------

export const getGptChatHIstory = (
  token,
  company_id,
  is_company_based,
  chat_id
) => {
  return (dispatch, getState) => {
    // dispatch(set_loader);
    const data = encrypt({
      "user-token": token,
      is_company_based,
      chat_group_id: chat_id,
      company_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/get_chat_history ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(setChatConversations(responseJson?.result));
        }

        // dispatch(unset_loader);

        // dispatch(unset_loader);
      });
  };
};

export const viewChatGroups = (token) => {
  return async (dispatch) => {
    const data = encrypt({
      "user-token": token,
    });

    return await fetch(UNIVERSAL.BASEURL + "/python_apis/get_chat_groups ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then(async (responseJson) => {
        if (responseJson.status) {
          await dispatch(setChatGroups(responseJson?.result.reverse()));
        }
      });
  };
};

export const deleteChat = (token, chat_id) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      chat_id,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/delete_chat_group ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(viewChatGroups(token));
        }
      });
  };
};

export const updateChatName = (token, chat_id, group_name) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      chat_group_id: chat_id,
      group_name,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/update_chat_group_name  ", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          // console.log(responseJson);
          dispatch(viewChatGroups(token));
        }
      });
  };
};

export const updateGptTour = (token, is_company_tour = false) => {
  return (dispatch) => {
    const data = encrypt({
      "user-token": token,
      is_company_tour,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/set_tour_completed", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          localStorage.setItem(
            "chat_tour_completed",
            responseJson.result?.chat_tour_completed
          );
          localStorage.setItem(
            "company_tour_completed",
            responseJson.result?.company_tour_completed
          );
          // console.log(responseJson);
          // dispatch(viewChatGroups(token));
        }
      });
  };
};

export const updateChatFeedBack = (
  token,
  chat_id,
  response,
  is_liked,
  disliked_rating,
  is_company_based
) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      chat_id,
      response,
      is_liked,
      disliked_rating,
      is_company_based,
    });

    return fetch(UNIVERSAL.BASEURL + "/python_apis/chat_feedback", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
          // console.log(responseJson);
          // dispatch(viewChatGroups(token));
        } else {
          dispatch(
            set_snack_bar(responseJson.status, "Data Updated Successfully")
          );

          dispatch(unset_loader());
        }
      });
  };
};

export const sendPremiumPlanRequest = (
  token,
  name,
  email,
  organization_name,
  section
) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      email,
      name,
      organization_name,
      section,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/send_email", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
          // console.log(responseJson);
          // dispatch(viewChatGroups(token));
        }
      });
  };
};

export const sendFeedBack = (token, feedback, rating) => {
  return (dispatch) => {
    dispatch(set_loader());
    const data = encrypt({
      "user-token": token,
      feedback,
      rating,
    });

    return fetch(UNIVERSAL.BASEURL + "/users/send_feedback", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ data: data }),
    })
      .then((response) => response.json())
      .then((responseJson) => {
        if (responseJson.status) {
          dispatch(set_snack_bar(responseJson.status, responseJson.message));
          dispatch(unset_loader());
        }
      });
  };
};

// ------------------ bot actions ----------------

export const checkBotAddChat = () => {
  return (dispatch) => {
    const data = {
      user_details: { _id: "63e5f4b4a419ced88b27ba61" },
      question: "Test question for bot",
      company_id: "",
      chat_group_id: "",
      is_company_based: false,
      isin: "",
    };

    return fetch(UNIVERSAL.BASEURL + "/python_apis/ua_add_chat", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => response?.json())
      .then((responseJson) => {
        console.log(responseJson);
        if (responseJson.status) {
          return responseJson?.result;
        }
      });
  };
};
